import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import { PreOrderLink } from '@ancon/wildcat-types'
import omit from 'lodash/omit'

import { CustomerPreOrderListType, PreOrderCheckoutEntityType } from '../types'
import { fetchCheckoutStatus } from '../../checkout/store/checkoutThunks'
import pickCustomerPreOrderFromCheckout from '../utils/pickCustomerPreOrderFromCheckout'
import storedPreOrderUserToken from '../utils/storedPreOrderUserToken'
import { signOut } from '../../auth/store/authThunks'

import preOrderReducers from './preOrderReducers'
import {
  addPreOrderCheckoutItems,
  confirmPreOrderCheckout,
  createGroupPreOrder,
  deletePreOrderAttendee,
  fetchCalendarMonthPreOrders,
  fetchCustomerPreOrderDetails,
  fetchPreOrderCheckout,
  fetchPreOrderCheckoutSummary,
  fetchPreOrderLink,
  fetchPreOrderSettings,
  fetchUpcomingOrderList,
  preOrderAttendeeSignIn,
  updatePreOrderCheckout,
  validatePreOrderCheckout,
} from './preOrderThunks'
import preOrderInitialState from './preOrderInitialState'
import customerPreOrdersEntityAdapter from './preOrderEntityAdapters'

const preOrderSliceSlice = createSlice({
  name: 'preOrder',
  initialState: preOrderInitialState,
  reducers: preOrderReducers,
  extraReducers: builder => {
    // Sign out
    builder.addCase(signOut.fulfilled, state => {
      function clearCustomerPreOrdersIfExists(key: CustomerPreOrderListType) {
        if (state[key].ids.length > 0) {
          customerPreOrdersEntityAdapter.removeAll(state[key])
        }
      }
      clearCustomerPreOrdersIfExists('upcomingOrderList')
      clearCustomerPreOrdersIfExists('calendarPreOrderList')
    })
    // Pre-order link data
    builder.addCase(
      fetchPreOrderLink.fulfilled,
      (state, action: PayloadAction<PreOrderLink>) => {
        state.preOrderLink = action.payload
      },
    )
    // Fetch current pre-order checkout
    builder.addCase(fetchPreOrderCheckout.pending, state => {
      state.preOrderCheckoutFetchPending = true
    })
    builder.addCase(fetchPreOrderCheckout.fulfilled, (state, action) => {
      state.preOrderCheckoutFetchPending = false
      state.preOrderCheckout = action.payload

      // Update pre-order user name
      const storedPreOrderUser = storedPreOrderUserToken.getUser()
      if (
        storedPreOrderUser &&
        storedPreOrderUser?.isHost &&
        storedPreOrderUser?.id &&
        action.payload.attendees
      ) {
        const hostDetails = action.payload.attendees.find(
          attendee => attendee.id === storedPreOrderUser.id,
        )
        if (hostDetails) {
          const newUser = {
            ...storedPreOrderUser,
            name: hostDetails.name,
          }
          storedPreOrderUserToken.create(newUser)
          state.preOrderUser = newUser
        }
      }
    })
    builder.addCase(fetchPreOrderCheckout.rejected, state => {
      state.preOrderCheckoutFetchPending = false
    })
    // Fetch pre-order checkout summary
    builder.addCase(fetchPreOrderCheckoutSummary.pending, state => {
      state.preOrderCheckoutSummaryFetchPending = true
    })
    builder.addCase(fetchPreOrderCheckoutSummary.fulfilled, (state, action) => {
      const { checkoutSummary, outlet, timestamp } = action.payload

      let timestampDateValue = new Date(timestamp).valueOf()

      if (Number.isNaN(timestampDateValue)) {
        timestampDateValue = 0
      }

      // Do not override status if it is not a new one
      if (
        !Number.isNaN(checkoutSummary.checkoutStatus) &&
        timestampDateValue >= state.preOrderCheckoutStatusLastUpdatedAt
      ) {
        if (state.preOrderCheckoutSummary) {
          state.preOrderCheckoutSummary = {
            ...state.preOrderCheckoutSummary,
            ...omit(checkoutSummary, 'checkoutStatus'),
          }
        } else {
          state.preOrderCheckoutSummary = checkoutSummary
        }

        state.preOrderCheckoutStatusLastUpdatedAt = timestampDateValue
      } else {
        state.preOrderCheckoutSummary = checkoutSummary
      }

      state.preOrderCheckoutSummaryFetchPending = false
      state.preOrderSummaryOutletListItem = outlet
    })
    builder.addCase(fetchPreOrderCheckoutSummary.rejected, state => {
      state.preOrderCheckoutSummaryFetchPending = false
    })
    // Add pre-order checkout items
    builder.addCase(addPreOrderCheckoutItems.pending, state => {
      state.preOrderAddItemPending = true
    })
    // Delete pre-order attendee
    builder.addCase(deletePreOrderAttendee.pending, state => {
      state.preOrderAttendeeDeletePending = true
    })
    // Confirm pre-order checkout
    builder.addCase(confirmPreOrderCheckout.pending, state => {
      state.preOrderConfirmPending = true
    })
    // Validate pre-order checkout
    builder.addCase(validatePreOrderCheckout.pending, state => {
      state.preOrderValidatePending = true
    })
    builder.addCase(validatePreOrderCheckout.fulfilled, state => {
      const preOrderUser = storedPreOrderUserToken.getUser()
      if (preOrderUser) {
        state.preOrderUser = preOrderUser
      }
      state.preOrderValidatePending = false
    })
    builder.addCase(validatePreOrderCheckout.rejected, state => {
      state.preOrderValidatePending = false
    })
    // Status updates from polling
    builder.addCase(fetchCheckoutStatus.fulfilled, (state, action) => {
      const { timestamp, status } = action.payload

      let timestampDateValue = new Date(timestamp || 0).valueOf()

      if (Number.isNaN(timestampDateValue)) {
        timestampDateValue = 0
      }

      const lastUpdatedAtDateValue = new Date(
        state.preOrderCheckoutStatusLastUpdatedAt || 0,
      ).valueOf()

      function handleUpdateCheckoutIfExists(key: PreOrderCheckoutEntityType) {
        if (state[key] && timestampDateValue >= lastUpdatedAtDateValue) {
          if (state[key]?.id === action.meta.arg.checkoutId) {
            state[key]!.checkoutStatus = status
            state.preOrderCheckoutStatusLastUpdatedAt = timestampDateValue
          }
        }
      }

      handleUpdateCheckoutIfExists('preOrderCheckout')
      handleUpdateCheckoutIfExists('preOrderCheckoutSummary')
    })
    // Fetch upcoming order list
    builder.addCase(fetchUpcomingOrderList.pending, (state, action) => {
      state.upcomingOrderList.isFetchPending = true
      if (!action.meta.arg.offset) {
        state.upcomingOrderList.meta.count = 0
      }
    })
    builder.addCase(fetchUpcomingOrderList.fulfilled, (state, action) => {
      const { offset, limit } = action.meta.arg
      const { items, meta } = action.payload

      state.upcomingOrderList.hasMore = meta.offset + limit < meta.count

      if (offset) {
        customerPreOrdersEntityAdapter.upsertMany(
          state.upcomingOrderList,
          items,
        )
      } else {
        customerPreOrdersEntityAdapter.setAll(state.upcomingOrderList, items)
      }

      state.upcomingOrderList.meta = meta
      state.upcomingOrderList.isFetchPending = false
    })
    builder.addCase(fetchUpcomingOrderList.rejected, state => {
      state.upcomingOrderList.isFetchPending = false
    })
    // Fetch all pre-orders in selected calendar month
    builder.addCase(fetchCalendarMonthPreOrders.pending, state => {
      state.calendarPreOrderList.isFetchPending = true
    })
    builder.addCase(fetchCalendarMonthPreOrders.fulfilled, (state, action) => {
      if (action.meta.arg.removeExisting) {
        customerPreOrdersEntityAdapter.setAll(
          state.calendarPreOrderList,
          action.payload,
        )
      } else {
        customerPreOrdersEntityAdapter.upsertMany(
          state.calendarPreOrderList,
          action.payload,
        )
      }
      state.calendarPreOrderList.isFetchPending = false
    })
    builder.addCase(fetchCalendarMonthPreOrders.rejected, state => {
      state.calendarPreOrderList.isFetchPending = false
    })
    // Group order request
    builder.addCase(createGroupPreOrder.pending, state => {
      state.newGroupOrderCreatePending = true
    })
    builder.addCase(createGroupPreOrder.fulfilled, (state, action) => {
      const createdGroupOrder = action.payload
      const preOrderListItem = pickCustomerPreOrderFromCheckout(action.payload)
      customerPreOrdersEntityAdapter.addOne(
        state.calendarPreOrderList,
        preOrderListItem,
      )
      customerPreOrdersEntityAdapter.addOne(
        state.upcomingOrderList,
        preOrderListItem,
      )
      state.upcomingOrderList.meta.count += 1
      state.createdGroupOrderId = createdGroupOrder.id
      state.newGroupOrderCreatePending = false
    })
    builder.addCase(createGroupPreOrder.rejected, state => {
      state.newGroupOrderCreatePending = false
    })
    // Fetch selected pre-order details
    builder.addCase(fetchCustomerPreOrderDetails.pending, state => {
      state.selectedPreOrderFetchPending = true
    })
    builder.addCase(fetchCustomerPreOrderDetails.fulfilled, (state, action) => {
      state.selectedPreOrderDetails = action.payload
      state.selectedPreOrderFetchPending = false
    })
    builder.addCase(fetchCustomerPreOrderDetails.rejected, state => {
      state.selectedPreOrderFetchPending = false
    })
    // Update pre-order checkout
    builder.addCase(updatePreOrderCheckout.pending, state => {
      state.preOrderUpdatePending = true
    })
    // Pre-Order settings
    builder.addCase(fetchPreOrderSettings.pending, state => {
      state.preOrderSettingsFetchPending = true
      state.preOrderSettings = null
    })
    builder.addCase(fetchPreOrderSettings.fulfilled, (state, action) => {
      state.preOrderSettings = action.payload
      state.preOrderSettingsFetchPending = false
    })
    builder.addCase(fetchPreOrderSettings.rejected, state => {
      state.preOrderSettingsFetchPending = false
    })
    builder.addCase(preOrderAttendeeSignIn.fulfilled, (state, action) => {
      const { preOrderUser } = action.payload
      state.preOrderUser = preOrderUser
    })
    // Delete pre-order attendee finished
    builder.addMatcher(
      isAnyOf(
        deletePreOrderAttendee.fulfilled,
        deletePreOrderAttendee.rejected,
      ),
      state => {
        state.preOrderAttendeeDeletePending = false
      },
    )
    // Add pre-order checkout items finished
    builder.addMatcher(
      isAnyOf(
        addPreOrderCheckoutItems.fulfilled,
        addPreOrderCheckoutItems.rejected,
      ),
      state => {
        state.preOrderAddItemPending = false
      },
    )
    // Confirm pre-order finished
    builder.addMatcher(
      isAnyOf(
        confirmPreOrderCheckout.fulfilled,
        confirmPreOrderCheckout.rejected,
      ),
      state => {
        state.preOrderConfirmPending = false
      },
    )
    // Pre-order checkout update finished
    builder.addMatcher(
      isAnyOf(
        updatePreOrderCheckout.fulfilled,
        updatePreOrderCheckout.rejected,
      ),
      state => {
        state.preOrderUpdatePending = false
      },
    )
  },
})

export const {
  setPreOrderStartStep,
  setPreOrderHostInfoModalVisible,
  setMobilePreOrderMembersManageStep,
  setSelectedPreOrderAttendeeId,
  setPreOrderAttendeeRemoveModalVisible,
  setPreOrderUser,
  setPreOrderAttendeeConfirmPendingModalVisible,
  setPreOrderAttendanceConfirmationModalVisible,
  setPreOrderAttendeeEmptyItemsWarningModalVisible,
  preOrderClearCheckoutSummary,
  preOrderCheckoutEmitStatusUpdatedEvent,
  preOrderCheckoutEmitItemStatusUpdatedEvent,
  preOrderCheckoutEmitDeliveryStatusUpdatedEvent,
  preOrderClearCheckout,
  preOrderUpdateMembersStatus,
  setOrderCalenderSelectedDate,
  setOrderCalendarSelectedMonth,
  preOrderGoNextNewOrderStep,
  preOrderGoBackNewOrderStep,
  setNewOrderDetails,
  resetNewOrderDetails,
  preOrderSetSelectedPreOrder,
  setNewPreOrderModalVisible,
  setNewPreOrderEditModeStep,
  setPreOrderLeaveModalVisible,
  setPreOrderExistWarningModalVisible,
  setPreOrderTimeChangeModalVisible,
  resetPreOrderState,
} = preOrderSliceSlice.actions

export default preOrderSliceSlice.reducer
