import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { ErrorCode } from '@ancon/wildcat-types'

import Button from '../Button'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { appErrorModalConfigSelector } from '../../store/appSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { appSetIsErrorModalVisible } from '../../store/appSlice'
import BodyText from '../BodyText'
import { ErrorModalType, InformativeError } from '../../types'
import { setProductDetailsModalVisible } from '../../../product/store/productSlice'
import storedPaymentCorrelationId from '../../utils/storedPaymentCorrelationId'
import {
  checkoutRetryAdyenPayment,
  checkoutSetIsVisibleBoxPickupScheduleOrderModal,
} from '../../../checkout/store/checkoutSlice'
import { checkRetryAdyenPayment } from '../../../check/store/checkSlice'
import { outletSetOrderConfigureFilterModal } from '../../../outlet/store/outletSlice'
import { OrderConfigureFilterMode } from '../../../outlet/types'
import { setPreOrderTimeChangeModalVisible } from '../../../preOrder/store/preOrderSlice'

import styles from './ErrorModalFooter.module.scss'

export default function ErrorModalFooter() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('common')

  const errorModalConfig = useAppSelector(appErrorModalConfigSelector)

  function handleRequestClose() {
    switch (errorModalConfig?.errorModalType) {
      case ErrorModalType.ProductOutOfStock:
        dispatch(setProductDetailsModalVisible(false))
        break
      case ErrorModalType.CheckoutPaymentError:
        storedPaymentCorrelationId.refresh()
        dispatch(checkoutRetryAdyenPayment())
        break
      case ErrorModalType.CheckPaymentError:
        storedPaymentCorrelationId.refresh()
        dispatch(checkRetryAdyenPayment())
        break
      default:
    }

    dispatch(appSetIsErrorModalVisible(false))
  }

  function handlePrimaryAction() {
    switch (errorModalConfig?.errorModalType) {
      case ErrorModalType.CheckoutBoxPickupUnAvailableProducts:
        dispatch(checkoutSetIsVisibleBoxPickupScheduleOrderModal(true))
        break
      case ErrorModalType.CheckoutUnavailableOrderTime: {
        const orderTimeErrorModalConfig =
          errorModalConfig as InformativeError<ErrorCode.CheckoutUnavailableOrderTime>
        const isPreOrderCheckout =
          !!orderTimeErrorModalConfig?.additionalData?.isPreOrderCheckout

        if (isPreOrderCheckout) {
          dispatch(setPreOrderTimeChangeModalVisible(true))
        } else {
          dispatch(
            outletSetOrderConfigureFilterModal(
              OrderConfigureFilterMode.OrderTime,
            ),
          )
        }

        break
      }
      default:
    }

    dispatch(appSetIsErrorModalVisible(false))
  }

  switch (errorModalConfig?.errorModalType) {
    case ErrorModalType.CheckoutItemPriceChanged:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('okay')}</BodyText>
        </Button>
      )
    case ErrorModalType.ProductOutOfStock:
    case ErrorModalType.OutletNotAvailableForOrders:
    case ErrorModalType.CheckoutFetchError:
    case ErrorModalType.CheckoutAddTipError:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('close')}</BodyText>
        </Button>
      )
    case ErrorModalType.CheckoutBoxPickupUnAvailableProducts:
      return (
        <>
          <Button
            size="large"
            variant="secondary"
            className={styles.button}
            onClick={handleRequestClose}
          >
            <BodyText className={clsx(styles.buttonText, styles.secondary)}>
              {t('retry')}
            </BodyText>
          </Button>
          <Button
            size="large"
            className={styles.button}
            onClick={handlePrimaryAction}
          >
            <BodyText className={styles.buttonText}>
              {t('boxPickup.changeTimeSlot')}
            </BodyText>
          </Button>
        </>
      )
    case ErrorModalType.CheckoutUnavailableOrderTime:
      return (
        <>
          <Button
            size="large"
            variant="secondary"
            className={styles.button}
            onClick={handleRequestClose}
          >
            <BodyText className={clsx(styles.buttonText, styles.secondary)}>
              {t('retry')}
            </BodyText>
          </Button>
          <Button
            size="large"
            className={styles.button}
            onClick={handlePrimaryAction}
          >
            <BodyText className={styles.buttonText}>
              {t('checkout.changeOrderTime')}
            </BodyText>
          </Button>
        </>
      )
    default:
      return (
        <Button
          size="large"
          className={styles.button}
          onClick={handleRequestClose}
        >
          <BodyText className={styles.buttonText}>{t('retry')}</BodyText>
        </Button>
      )
  }
}
