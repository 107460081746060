import getT from 'next-translate/getT'
import {
  AttendeePreOrderStatus,
  CheckoutCancelActor,
  CheckoutDetails,
  CheckoutOrderType,
  CustomerPreOrderCheckout,
  CustomerPreOrdersPaginatedResponse,
  ErrorCode,
  OrderFormat,
  OutletListItem,
  PreOrderAttendeeSignInResponse,
  PreOrderCheckoutDetails,
  PreOrderLink,
  PreOrderAttendeeItems,
  PreOrderSettings,
  PreOrderStatus,
  ServiceTimeKindType,
  SystemVisibility,
  ServiceTime,
} from '@ancon/wildcat-types'
import base64URLEncode from '@ancon/wildcat-utils/codec/base64URLEncode'
import { CheckoutRequestItem } from '@ancon/wildcat-utils/checkout/types'
import getCheckoutRequestItemFromCheckoutItem from '@ancon/wildcat-utils/checkout/getCheckoutRequestItemFromCheckoutItem'
import isCheckoutItemsAreEqual from '@ancon/wildcat-utils/checkout/isCheckoutItemsAreEqual'
import getSHA256Hash from '@ancon/wildcat-utils/hashing/getSHA256Hash'
import serializeError from '@ancon/wildcat-utils/error/serializeError'
import moment from 'moment'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import api from '../../../api'
import { appLanguageSelector } from '../../app/store/appSelectors'
import localStorageUtils from '../../app/utils/localStorageUtils'
import { Toast } from '../../notification'
import {
  CheckoutErrorContextType,
  CheckoutOutOfStockAdditionalData,
  LocalStorageCartMeta,
} from '../../checkout/types'
import { fetchCheckoutOutletListItem } from '../../checkout/store/checkoutThunks'
import { PreOrderUser } from '../types'
import {
  checkoutCurrentCheckoutOutletEnableCustomOrderNotesSelector,
  checkoutFiltersSelector,
  outletSelectedOutletHasCheckoutSelector,
} from '../../checkout/store/checkoutSelectors'
import {
  clientContextCurrentCompanyUserIdSelector,
  clientContextCurrentUserCompanyIdSelector,
  clientContextCustomerIdSelector,
  clientContextCustomerNameSelector,
  clientContextCustomerPhoneNumberSelector,
} from '../../clientContext/store/clientContextSelectors'
import { appShowError } from '../../app/store/appSlice'
import { ErrorModalType } from '../../app/types'
import { outletSetOutletFilters } from '../../outlet/store/outletSlice'
import getCheckoutErrorModalConfig from '../../checkout/utils/getCheckoutErrorModalConfig'
import { outletSelectedOutletTenantIdSelector } from '../../outlet/store/outletSelector'
import { outletsSelectedRestaurantIdSelector } from '../../outlets/store/outletsSelectors'
import storedPreOrderUserToken from '../utils/storedPreOrderUserToken'
import getPreOrderLinkAttendeeStatus from '../utils/getPreOrderLinkAttendeeStatus'
import getTenantOutletAPIFilters from '../../app/utils/getTenantOutletAPIFilters'

import {
  preOrderAttendeeItemsByIdSelector,
  preOrderCheckoutIdSelector,
  preOrderCheckoutOrderInstructionsSelector,
  preOrderCheckoutOutletIdSelector,
  preOrderCheckoutSelector,
  preOrderHostEmailHashSelector,
  preOrderLinkCheckoutIdSelector,
  preOrderLinkOutletIdSelector,
  preOrderLinkPreOrderStatusSelector,
  preOrderLinkSelector,
  preOrderNewGroupOrderNameSelector,
  preOrderNewGroupOrderSizeSelector,
  preOrderNewOrderOrderFormatSelector,
  preOrderNewOrderTimeSelector,
  preOrderSelectedListItemHostAttendeeIdSelector,
  preOrderSelectedListItemOrderTypeSelector,
  preOrderSelectedListItemOutletIdSelector,
  preOrderSelectedPreOrderIdSelector,
  preOrderUserIdSelector,
} from './preOrderSelectors'
import {
  preOrderCheckoutSummaryOutletIdSelector,
  preOrderSummaryCheckoutIdSelector,
} from './preOrderSummarySelectors'

export const fetchPreOrderLink = createAppAsyncThunk<
  PreOrderLink,
  { preOrderId: string }
>('preOrder/fetchPreOrderLink', async ({ preOrderId }, { rejectWithValue }) => {
  try {
    const response = await api.core.preOrders.get.preOrderLink({
      checkoutId: preOrderId,
    })

    return response.data.preOrder
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const preOrderAttendeeSignIn = createAppAsyncThunk<
  {
    signedInAttendee: PreOrderAttendeeSignInResponse['signedInAttendee']
    preOrderUser: PreOrderUser
  },
  { name?: string; email: string; pinCode?: string }
>(
  'preOrder/preOrderAttendeeSignIn',
  async ({ name, email, pinCode }, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'preOrder')

    const outletId = preOrderLinkOutletIdSelector(getState())!
    const checkoutId = preOrderLinkCheckoutIdSelector(getState())!
    const hostEmailHash = preOrderHostEmailHashSelector(getState())!
    const preOrderLink = preOrderLinkSelector(getState())
    const preOrderStatus = preOrderLinkPreOrderStatusSelector(getState())!

    const userEmailHash = await getSHA256Hash(email)
    const isHost = userEmailHash === hostEmailHash

    try {
      const preOrderAPIToken = base64URLEncode({ email, pin: pinCode })

      const response = await api.core.preOrders.put.attendeeSignIn(
        { name: name ?? null, email },
        { outletId, checkoutId },
        { 'preorder-token': preOrderAPIToken },
      )

      const { signedInAttendee } = response.data
      const { attendeeId } = signedInAttendee

      const attendeeStatus = getPreOrderLinkAttendeeStatus(
        preOrderLink!,
        attendeeId,
      )
      const shouldAddCartMeta = isHost
        ? preOrderStatus === PreOrderStatus.Created ||
          preOrderStatus === PreOrderStatus.Started ||
          preOrderStatus === PreOrderStatus.Activated
        : attendeeStatus === AttendeePreOrderStatus.Pending

      if (shouldAddCartMeta) {
        localStorageUtils.setItem<LocalStorageCartMeta>('cartMeta', {
          outletId,
          checkoutId,
          isPreOrderCheckout: true,
        })
      }

      const preOrderUser: PreOrderUser = {
        id: attendeeId,
        name,
        email,
        pin: pinCode,
        isHost,
      }
      storedPreOrderUserToken.create(preOrderUser)

      return { signedInAttendee, preOrderUser }
    } catch (error) {
      Toast.error(
        t('errors.preOrderAttendeeSignInFailure.title'),
        t('errors.preOrderAttendeeSignInFailure.message'),
      )

      return rejectWithValue(error)
    }
  },
)

export const activatePreOrder = createAppAsyncThunk<
  void,
  { email: string; pinCode: string }
>(
  'preOrder/activatePreOrder',
  async ({ email, pinCode }, { dispatch, getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const outletId = preOrderLinkOutletIdSelector(getState())!
    const checkoutId = preOrderLinkCheckoutIdSelector(getState())!

    const t = await getT(locale, 'preOrder')

    try {
      const preOrderAPIToken = base64URLEncode({ email, pin: pinCode })

      await api.core.preOrders.put.activate(
        {},
        { outletId, checkoutId },
        { 'preorder-token': preOrderAPIToken },
      )

      await dispatch(preOrderAttendeeSignIn({ email, pinCode })).unwrap()

      return undefined
    } catch (error) {
      Toast.error(
        t('errors.preOrderActivateFailure.title'),
        t('errors.preOrderActivateFailure.message'),
      )

      return rejectWithValue(error)
    }
  },
)

export const fetchPreOrderCheckout = createAppAsyncThunk<
  PreOrderCheckoutDetails,
  | Partial<{
      outletId: string
      checkoutId: string
      attendeeId: string
    }>
  | undefined
>(
  'preOrder/fetchPreOrderCheckout',
  async (args, { getState, dispatch, rejectWithValue }) => {
    const currentOutletId =
      args?.outletId ?? preOrderCheckoutOutletIdSelector(getState())!
    const currentCheckoutId =
      args?.checkoutId ?? preOrderCheckoutIdSelector(getState())!
    const currentAttendeeId =
      args?.attendeeId ?? preOrderUserIdSelector(getState())!

    const preOrderAPIToken = storedPreOrderUserToken.getAPIToken()

    try {
      const response = await api.core.preOrders.get.details(
        {
          outletId: currentOutletId,
          preOrderId: currentCheckoutId,
          attendeeId: currentAttendeeId,
        },
        { 'preorder-token': preOrderAPIToken },
      )

      await dispatch(fetchPreOrderLink({ preOrderId: currentCheckoutId }))

      return response.data.preOrderCheckout
    } catch (error) {
      // TODO: Handle showing error messages
      return rejectWithValue(error)
    }
  },
)

export const validatePreOrderCheckout = createAppAsyncThunk<
  void,
  Partial<{
    checkoutId: string
    outletId: string
    attendeeId: string
  }>
>(
  'preOrder/validatePreOrderCheckout',
  async (args, { getState, dispatch, rejectWithValue }) => {
    function getCurrentCheckoutMeta() {
      const internalCheckoutId = preOrderCheckoutIdSelector(getState())
      const internalOutletId = preOrderCheckoutOutletIdSelector(getState())
      const internalAttendeeId = preOrderUserIdSelector(getState())

      if (internalCheckoutId && internalOutletId && internalAttendeeId) {
        return {
          checkoutId: internalCheckoutId,
          outletId: internalOutletId,
          attendeeId: internalAttendeeId,
        }
      }
      // Get checkout meta & attendee token from local storage
      const checkout =
        localStorageUtils.getItem<LocalStorageCartMeta>('cartMeta')
      const preOrderUser = storedPreOrderUserToken.getUser()

      if (checkout && preOrderUser) {
        return {
          checkoutId: checkout.checkoutId,
          outletId: checkout.outletId,
          attendeeId: preOrderUser.id,
        }
      }

      return undefined
    }

    // Skip checkout validation if checkoutId and outletId are not exist
    const checkoutMeta = getCurrentCheckoutMeta()
    if (!checkoutMeta) {
      return rejectWithValue({})
    }

    const checkoutId = args.checkoutId ?? checkoutMeta.checkoutId
    const outletId = args.outletId ?? checkoutMeta.outletId
    const attendeeId = args.attendeeId ?? checkoutMeta.attendeeId

    try {
      await dispatch(
        fetchPreOrderCheckout({ outletId, checkoutId, attendeeId }),
      ).unwrap()

      await dispatch(fetchCheckoutOutletListItem({ outletId })).unwrap()

      return undefined
    } catch (err) {
      // TODO:  handle error
      return rejectWithValue(err)
    }
  },
)

export const updatePreOrderCheckout = createAppAsyncThunk<
  void,
  Partial<{
    memberItems: PreOrderAttendeeItems[]
    instructions: string
    companyId: string | null
    serviceTime: ServiceTime
    checkoutErrorContextType: CheckoutErrorContextType
  }>
>(
  'preOrder/updatePreOrderCheckout',
  async (
    {
      memberItems,
      instructions,
      companyId: externalCompanyId,
      serviceTime,
      checkoutErrorContextType = CheckoutErrorContextType.UpdateCheckout,
    },
    { getState, dispatch, rejectWithValue },
  ) => {
    const locale = appLanguageSelector(getState())

    const outletId = preOrderCheckoutOutletIdSelector(getState())!
    const checkoutId = preOrderCheckoutIdSelector(getState())!
    const customerId = clientContextCustomerIdSelector(getState())
    const checkoutFilters = checkoutFiltersSelector(getState())
    const checkoutInstructions =
      preOrderCheckoutOrderInstructionsSelector(getState())
    const customerName = clientContextCustomerNameSelector(getState())
    const customerPhone = clientContextCustomerPhoneNumberSelector(getState())
    const preOrderUserId = preOrderUserIdSelector(getState())
    const outletEnableCustomOrderNotes =
      checkoutCurrentCheckoutOutletEnableCustomOrderNotesSelector(getState())

    const t = await getT(locale, 'common')

    if (!outletId || !checkoutId) {
      return undefined
    }

    const delivery = checkoutFilters.delivery
      ? {
          ...checkoutFilters.delivery,
          recipient: customerName,
          phone: customerPhone!,
        }
      : null

    const orderInstructions = outletEnableCustomOrderNotes
      ? instructions || checkoutInstructions
      : null

    try {
      const preOrderAPIToken = storedPreOrderUserToken.getAPIToken()

      const response = await api.core.preOrders.put.update(
        {
          customerId,
          /** Pre order checkout company id cannot be removed */
          companyId: externalCompanyId ?? undefined,
          delivery,
          orderFormat: checkoutFilters.orderFormat!,
          serviceTime: serviceTime || checkoutFilters.serviceTime!,
          attendeeItems: memberItems ?? [],
          instructions: orderInstructions || null,
          attendeeId: preOrderUserId ?? null,
        },
        {
          outletId,
          checkoutId,
        },
        { 'preorder-token': preOrderAPIToken },
      )

      dispatch(fetchPreOrderCheckout({ outletId, checkoutId }))

      if (response && !!response?.data?.items?.length) {
        const { items: responseItems } = response.data
        // Show price change modal
        const errorModalConfig = await getCheckoutErrorModalConfig(
          {
            response: {
              data: {
                additionalData: responseItems,
                errorCode: ErrorCode.CheckoutPriceChanged,
              },
            },
          },
          {
            title: t('errors.checkoutPaymentFailure.title'),
            message: t('errors.unknown.message'),
          },
          undefined,
          undefined,
          locale,
        )
        dispatch(
          appShowError(
            errorModalConfig
              ? {
                  errorModalType: ErrorModalType.CheckoutItemPriceChanged,
                  ...errorModalConfig,
                }
              : undefined,
          ),
        )
      }

      return undefined
    } catch (err: any) {
      // Update outlet filters to the checkout filters
      const outletSelectedOutletHasCheckout =
        outletSelectedOutletHasCheckoutSelector(getState())
      const checkoutDetails = preOrderCheckoutSelector(getState())

      if (checkoutDetails && outletSelectedOutletHasCheckout) {
        dispatch(
          outletSetOutletFilters({
            orderFormat: checkoutDetails.orderFormat,
            serviceTime: checkoutDetails.serviceTime,
            delivery: checkoutDetails.delivery || undefined,
          }),
        )
      }

      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericUpdateFailure.title'),
          message: t('errors.checkoutGenericUpdateFailure.message'),
        },
        checkoutErrorContextType,
        undefined,
        locale,
        { orderFormat: checkoutFilters.orderFormat! },
      )

      const isStockError =
        err?.response?.data?.errorCode === ErrorCode.PaymentAcceptOutOfStock

      if (isStockError) {
        const additionalData: CheckoutOutOfStockAdditionalData[] =
          err.response?.data?.additionalData || []

        if (
          checkoutErrorContextType !== CheckoutErrorContextType.UpdateCheckout
        ) {
          dispatch(
            appShowError(
              errorModalConfig
                ? {
                    errorModalType: ErrorModalType.GeneralCheckoutError,
                    ...errorModalConfig,
                    additionalData,
                  }
                : undefined,
            ),
          )
        }

        return rejectWithValue({
          ...errorModalConfig,
          stockErrorAdditionalData: additionalData,
        })
      }

      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )

      return rejectWithValue(errorModalConfig)
    }
  },
)

export const addPreOrderCheckoutItems = createAppAsyncThunk<
  void,
  {
    items: CheckoutRequestItem[]
    /** The ID of the attendee to add the items to */
    attendeeId?: string
  }
>(
  'preOrder/addPreOrderCheckoutItems',
  async ({ items, attendeeId }, { getState, dispatch }) => {
    const preOrderUserId = preOrderUserIdSelector(getState())

    const preOrderAttendeeId = attendeeId ?? preOrderUserId ?? null

    const checkoutItems = preOrderAttendeeItemsByIdSelector(
      getState(),
      preOrderAttendeeId,
    )

    const modifiedCheckoutItems = checkoutItems.reduce<CheckoutRequestItem[]>(
      (acc, checkoutItem) => {
        const checkoutRequestItem =
          getCheckoutRequestItemFromCheckoutItem(checkoutItem)

        items.every((newItem, index) => {
          if (isCheckoutItemsAreEqual(checkoutRequestItem, newItem)) {
            checkoutRequestItem.quantity += newItem.quantity
            items.splice(index, 1)
            return false
          }
          return true
        })

        acc.push(checkoutRequestItem)

        return acc
      },
      [],
    )

    const memberItems = [
      {
        attendeeId: preOrderAttendeeId!,
        items: [...modifiedCheckoutItems, ...items],
      },
    ]

    await dispatch(
      updatePreOrderCheckout({
        memberItems,
        checkoutErrorContextType: CheckoutErrorContextType.AddCheckoutItem,
      }),
    ).unwrap()

    await dispatch(fetchPreOrderCheckout())
  },
)

export const updatePreOrderCheckoutItem = createAppAsyncThunk<
  void,
  {
    checkoutItemId: string
    updatedItem: CheckoutRequestItem
    attendeeId?: string
  }
>(
  'preOrder/updatePreOrderCheckoutItem',
  async (
    { updatedItem, checkoutItemId, attendeeId },
    { getState, dispatch },
  ) => {
    const preOrderUserId = preOrderUserIdSelector(getState())

    const preOrderAttendeeId = attendeeId ?? preOrderUserId ?? null

    const checkoutItems = preOrderAttendeeItemsByIdSelector(
      getState(),
      preOrderAttendeeId,
    )

    const modifiedCheckoutItems = checkoutItems.reduce<CheckoutRequestItem[]>(
      (acc, checkoutItem) => {
        if (checkoutItem.id === checkoutItemId) {
          acc.push(updatedItem)
          return acc
        }

        const checkoutRequestItem =
          getCheckoutRequestItemFromCheckoutItem(checkoutItem)

        acc.push(checkoutRequestItem)

        return acc
      },
      [],
    )

    const memberItems = [
      {
        attendeeId: preOrderAttendeeId!,
        items: modifiedCheckoutItems,
      },
    ]

    await dispatch(
      updatePreOrderCheckout({
        memberItems,
        checkoutErrorContextType: CheckoutErrorContextType.UpdateCheckout,
      }),
    ).unwrap()
  },
)

export const changePreOrderCheckoutItemQty = createAppAsyncThunk<
  void,
  { checkoutItemId: string; quantity: number; attendeeId?: string }
>(
  'preOrder/changePreOrderCheckoutItemQty',
  async ({ checkoutItemId, quantity, attendeeId }, { getState, dispatch }) => {
    const preOrderUserId = preOrderUserIdSelector(getState())

    const preOrderAttendeeId = attendeeId ?? preOrderUserId ?? null

    const checkoutItems = preOrderAttendeeItemsByIdSelector(
      getState(),
      preOrderAttendeeId,
    )

    const modifiedCheckoutItems = checkoutItems.map(item => {
      const checkoutRequestItem = getCheckoutRequestItemFromCheckoutItem(item)

      if (item.id === checkoutItemId) {
        checkoutRequestItem.quantity = quantity
      }

      return checkoutRequestItem
    })

    const memberItems = [
      {
        attendeeId: preOrderAttendeeId!,
        items: modifiedCheckoutItems,
      },
    ]

    await dispatch(
      updatePreOrderCheckout({
        memberItems,
        checkoutErrorContextType:
          CheckoutErrorContextType.ChangeCheckoutItemQuantity,
      }),
    ).unwrap()
  },
)

export const preOrderApplyDiscount = createAppAsyncThunk<
  void,
  { code: string }
>(
  'preOrder/preOrderApplyDiscount',
  async ({ code }, { getState, dispatch, rejectWithValue }) => {
    const outletId = preOrderCheckoutOutletIdSelector(getState())!
    const checkoutId = preOrderCheckoutIdSelector(getState())!
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await api.core.checkouts.put.applyDiscount(
        { code },
        { outletId, checkoutId },
      )
      dispatch(fetchPreOrderCheckout())

      return undefined
    } catch (err) {
      const discountError = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.discountGenericError.title'),
          message: t('errors.discountGenericError.message'),
        },
        undefined,
        undefined,
        locale,
      )

      return rejectWithValue(discountError)
    }
  },
)

export const deletePreOrderCheckoutItem = createAppAsyncThunk<
  void,
  { checkoutItemId: string; attendeeId?: string }
>(
  'preOrder/deletePreOrderCheckoutItem',
  async ({ checkoutItemId, attendeeId }, { getState, dispatch }) => {
    const preOrderUserId = preOrderUserIdSelector(getState())

    const preOrderAttendeeId = attendeeId ?? preOrderUserId ?? null

    const checkoutItems = preOrderAttendeeItemsByIdSelector(
      getState(),
      preOrderAttendeeId,
    )

    const modifiedCheckoutItems = checkoutItems.reduce<CheckoutRequestItem[]>(
      (acc, checkoutItem) => {
        if (checkoutItem.id !== checkoutItemId) {
          const checkoutRequestItem =
            getCheckoutRequestItemFromCheckoutItem(checkoutItem)
          acc.push(checkoutRequestItem)
        }

        return acc
      },
      [],
    )

    const memberItems = [
      {
        attendeeId: preOrderAttendeeId!,
        items: modifiedCheckoutItems,
      },
    ]

    await dispatch(
      updatePreOrderCheckout({
        memberItems,
        checkoutErrorContextType:
          CheckoutErrorContextType.ChangeCheckoutItemQuantity,
      }),
    ).unwrap()
  },
)

export const deletePreOrderAttendee = createAppAsyncThunk<
  void,
  { attendeeId: string }
>(
  'preOrder/deletePreOrderAttendee',
  async ({ attendeeId }, { getState, dispatch }) => {
    const outletId = preOrderCheckoutOutletIdSelector(getState())!
    const checkoutId = preOrderCheckoutIdSelector(getState())!

    const preOrderAPIToken = storedPreOrderUserToken.getAPIToken()

    await api.core.preOrders.delete.deleteAttendee(
      undefined,
      {
        outletId,
        checkoutId,
        attendeeId,
      },
      { 'preorder-token': preOrderAPIToken },
    )

    dispatch(fetchPreOrderCheckout())
  },
)

export const confirmPreOrderCheckout = createAppAsyncThunk<void>(
  'preOrder/confirmPreOrderCheckout',
  async (_, { getState }) => {
    const locale = appLanguageSelector(getState())

    const outletId = preOrderCheckoutOutletIdSelector(getState())!
    const checkoutId = preOrderCheckoutIdSelector(getState())!
    const attendeeId = preOrderUserIdSelector(getState())!

    const t = await getT(locale, 'preOrder')

    try {
      const preOrderAPIToken = storedPreOrderUserToken.getAPIToken()

      await api.core.preOrders.put.confirm(
        undefined,
        {
          outletId,
          checkoutId,
          attendeeId,
        },
        { 'preorder-token': preOrderAPIToken },
      )
    } catch (error) {
      Toast.error(
        t('errors.preOrderConfirmFailure.title'),
        t('errors.preOrderConfirmFailure.message'),
      )
    }
  },
)

export const fetchPreOrderCheckoutSummary = createAppAsyncThunk<
  {
    checkoutSummary: PreOrderCheckoutDetails
    outlet: OutletListItem
    timestamp: string
  },
  {
    outletId: string
    checkoutId: string
    attendeeId?: string
  }
>(
  'preOrder/fetchPreOrderCheckoutSummary',
  async (
    { outletId, checkoutId, attendeeId },
    { dispatch, getState, rejectWithValue },
  ) => {
    const preOrderAPIToken = storedPreOrderUserToken.getAPIToken()
    const preOrderUserId = preOrderUserIdSelector(getState())!

    try {
      const preOrderResponse = await api.core.preOrders.get.details(
        {
          outletId,
          preOrderId: checkoutId,
          attendeeId: attendeeId ?? preOrderUserId,
        },
        { 'preorder-token': preOrderAPIToken },
      )

      await dispatch(fetchPreOrderLink({ preOrderId: checkoutId }))

      const outletListItemResponse = await api.core.outlets.get.nearby({
        systemVisibility: SystemVisibility.OrderWeb,
        orderFormats: OrderFormat.All,
        limit: 1,
        offset: 0,
        outletId,
      })

      const [outlet] = outletListItemResponse.data.items

      return {
        checkoutSummary: preOrderResponse.data.preOrderCheckout,
        outlet,
        timestamp: preOrderResponse.headers.date,
      }
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const cancelPreOrderCheckout = createAppAsyncThunk<void>(
  'preOrder/cancelPreOrderCheckout',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const checkoutId = preOrderSummaryCheckoutIdSelector(getState())!
    const outletId = preOrderCheckoutSummaryOutletIdSelector(getState())!
    const cancelActorId = clientContextCustomerIdSelector(getState())!
    const locale = appLanguageSelector(getState())

    const t = await getT(locale, 'common')

    try {
      await api.core.checkouts.put.cancel(
        { cancelActor: CheckoutCancelActor.Customer, cancelActorId },
        { outletId, checkoutId },
      )
      await dispatch(
        fetchPreOrderCheckoutSummary({ outletId, checkoutId }),
      ).unwrap()

      return undefined
    } catch (err) {
      const errorModalConfig = await getCheckoutErrorModalConfig(
        err,
        {
          title: t('errors.checkoutGenericCancelFailure.title'),
          message: t('errors.checkoutGenericCancelFailure.message'),
        },
        undefined,
        undefined,
        locale,
      )
      dispatch(
        appShowError(
          errorModalConfig
            ? {
                errorModalType: ErrorModalType.GeneralCheckoutError,
                ...errorModalConfig,
              }
            : undefined,
        ),
      )
      return rejectWithValue(errorModalConfig)
    }
  },
)

export const createGroupPreOrder = createAppAsyncThunk<PreOrderCheckoutDetails>(
  'preOrder/createGroupPreOrder',
  async (_, { getState, rejectWithValue }) => {
    const locale = appLanguageSelector(getState())

    const outletId = outletsSelectedRestaurantIdSelector(getState())!
    const companyMemberId =
      clientContextCurrentCompanyUserIdSelector(getState())!
    const companyId = clientContextCurrentUserCompanyIdSelector(getState())!
    const preOrderName = preOrderNewGroupOrderNameSelector(getState())!
    const orderTimestamp = preOrderNewOrderTimeSelector(getState())
    const orderFormat = preOrderNewOrderOrderFormatSelector(getState())!
    const groupSize = preOrderNewGroupOrderSizeSelector(getState())!

    const t = await getT(locale, 'preOrder')

    try {
      const response = await api.core.preOrders.post.createGroupOrder(
        {
          preOrderName,
          orderFormat,
          serviceTime: {
            kind: ServiceTimeKindType.AtSpecifiedTime,
            time: moment(orderTimestamp).toISOString(),
          },
          companyId,
          hostCompanyMemberId: companyMemberId,
          noOfAttendees: groupSize,
        },
        { outletId },
      )

      return response.data.preOrderCheckout
    } catch (error: any) {
      Toast.error(
        t('errors.groupOrderCreateFailure.title'),
        t('errors.groupOrderCreateFailure.message'),
      )

      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchCustomerPreOrders = createAppAsyncThunk<
  CustomerPreOrdersPaginatedResponse,
  Partial<{
    fromDate: string
    toDate: string
    limit: number
    offset: number
    isIncludeCompletedOrders: boolean
  }>
>(
  'preOrder/fetchCustomerPreOrders',
  async (
    { fromDate, toDate, offset, limit, isIncludeCompletedOrders },
    { getState, rejectWithValue },
  ) => {
    const tenantOutletFilters = getTenantOutletAPIFilters()

    const companyId = clientContextCurrentUserCompanyIdSelector(getState())!

    // If no fromDate is given, default to today
    const startOfFromDate = moment(fromDate).startOf('day').toISOString()

    // Filter orders within given date range
    const serviceTimeFilter = [`gte:${startOfFromDate}`]
    if (toDate) {
      const endOfToDate = moment(toDate).endOf('day').toISOString()
      serviceTimeFilter.push(`lte:${endOfToDate}`)
    }

    // Filter only pre-orders
    const orderTypeFilter = [
      CheckoutOrderType.PreOrder,
      CheckoutOrderType.PreOrderLink,
      CheckoutOrderType.GroupPreOrder,
    ].map(type => `in:${type}`)

    const preOrderStatus = [
      PreOrderStatus.ApprovalPending,
      PreOrderStatus.Activated,
      PreOrderStatus.Started,
      PreOrderStatus.Waiting,
      PreOrderStatus.Pending,
    ]

    if (isIncludeCompletedOrders) {
      preOrderStatus.push(PreOrderStatus.Completed)
    }

    const preOrderStatusFilter = preOrderStatus.map(type => `in:${type}`)

    try {
      const response = await api.core.preOrders.get.customerPreOrders({
        companyId,
        offset,
        limit,
        order: 'serviceTime:asc',
        filter: {
          ...tenantOutletFilters,
          serviceTime: serviceTimeFilter,
          orderType: orderTypeFilter,
          status: preOrderStatusFilter,
        },
      })

      return response.data
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchUpcomingOrderList = createAppAsyncThunk<
  CustomerPreOrdersPaginatedResponse,
  {
    offset: number
    limit: number
  }
>(
  'preOrder/fetchUpcomingOrderList',
  async ({ offset, limit }, { dispatch, rejectWithValue }) => {
    try {
      const paginatedOrders = await dispatch(
        fetchCustomerPreOrders({
          // From today onwards
          fromDate: moment().startOf('day').toISOString(),
          offset,
          limit,
        }),
      ).unwrap()

      return paginatedOrders
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchCalendarMonthPreOrders = createAppAsyncThunk<
  Array<CustomerPreOrderCheckout>,
  {
    startOfMonth?: string
    removeExisting?: boolean
  }
>(
  'preOrder/fetchCalendarMonthPreOrders',
  async ({ startOfMonth }, { dispatch, rejectWithValue }) => {
    const endOfMonth = moment(startOfMonth).endOf('month').format()

    try {
      const response = await dispatch(
        fetchCustomerPreOrders({
          fromDate: startOfMonth,
          toDate: endOfMonth,
          // Parsing higher limit to fetch all orders in the month
          limit: 500,
          isIncludeCompletedOrders: true,
        }),
      ).unwrap()

      return response.items
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchCustomerPreOrderDetails = createAppAsyncThunk<
  PreOrderCheckoutDetails | CheckoutDetails
>(
  'preOrder/fetchCustomerPreOrderDetails',
  async (_, { getState, dispatch, rejectWithValue }) => {
    const orderType = preOrderSelectedListItemOrderTypeSelector(getState())!
    const preOrderId = preOrderSelectedPreOrderIdSelector(getState())!
    const outletId = preOrderSelectedListItemOutletIdSelector(getState())!
    const attendeeId =
      preOrderSelectedListItemHostAttendeeIdSelector(getState())!

    try {
      if (
        orderType === CheckoutOrderType.PreOrderLink ||
        orderType === CheckoutOrderType.GroupPreOrder
      ) {
        const response = await api.core.preOrders.get.details({
          outletId,
          preOrderId,
          attendeeId,
        })

        await dispatch(fetchPreOrderLink({ preOrderId }))

        return response.data.preOrderCheckout
      }

      const response = await api.core.checkouts.get.details({
        outletId,
        checkoutId: preOrderId,
      })

      return response.data
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)

export const fetchPreOrderSettings = createAppAsyncThunk<PreOrderSettings>(
  'preOrder/fetchPreOrderSettings',
  async (_, { getState, rejectWithValue }) => {
    const tenantId = outletSelectedOutletTenantIdSelector(getState())!

    try {
      const response = await api.core.preOrders.get.preOrderSettings({
        tenantId,
      })

      return response.data.settings
    } catch (error: any) {
      return rejectWithValue(serializeError(error))
    }
  },
)
