import {
  CheckoutDetails,
  CustomerPreOrderCheckout,
  OrderFormat,
  OutletListItem,
  PaginationMeta,
  PreOrderCheckoutDetails,
  PreOrderLink,
  PreOrderSettings,
} from '@ancon/wildcat-types'
import { EntityState } from '@reduxjs/toolkit'

export type PreOrderPageQuery = {
  preOrderId?: string
}

export type GroupOrderPageQuery = {
  groupOrderId?: string
}

export type PreOrderStatusPageQuery = {
  outletId?: string
  preOrderId?: string
  /** Whether or not redirected from payment completion */
  redirect?: boolean
}

export enum PreOrderStartStep {
  First = 1,
  Second,
}

export type PreOrderReducerState = {
  /* Pre order link details */
  preOrderLink: PreOrderLink | null

  /* First or second step (Required for host) */
  startStep: PreOrderStartStep

  /* Pre order host information modal visible state  */
  showPreOrderHostInfoModal: boolean

  /** Current pre-order checkout */
  preOrderCheckout: PreOrderCheckoutDetails | null
  preOrderSelectedAttendeeId: string | null
  preOrderCheckoutFetchPending: boolean
  preOrderAddItemPending: boolean
  preOrderAttendeeDeletePending: boolean
  preOrderConfirmPending: boolean
  preOrderUpdatePending: boolean
  preOrderValidatePending: boolean
  isPreOrderLeaveModalVisible: boolean
  isPreOrderExistWarningModalVisible: boolean
  isPreOrderTimeChangeModalVisible: boolean

  /** Pre-order checkout summary */
  preOrderCheckoutSummary: PreOrderCheckoutDetails | null
  preOrderCheckoutSummaryFetchPending: boolean
  preOrderSummaryOutletListItem: OutletListItem | null

  /** Current pre-order user */
  preOrderUser: PreOrderUser | null

  /* Host pre-order manage page */
  isMobilePreOrderMembersManageStep: boolean
  isPreOrderAttendeeRemoveModalVisible: boolean
  isAttendeeConfirmPendingModalVisible: boolean
  isAttendanceConfirmationModalVisible: boolean
  isAttendeeEmptyItemsWarningModalVisible: boolean

  preOrderCheckoutStatusLastUpdatedAt: number

  /**  WL company user dashboard */
  orderCalendarSelectedDate: string | null
  orderCalendarSelectedMonth: string | null // Start day of the month
  calendarPreOrderList: EntityState<CustomerPreOrderCheckout> & {
    isFetchPending: boolean
  }
  newOrderStep: NewOrderStep
  newOrderEditModeStep: NewOrderStep
  newOrder: NewOrderDetails
  isNewOrderModalVisible: boolean
  newGroupOrderCreatePending: boolean
  createdGroupOrderId: string | null
  upcomingOrderList: EntityState<CustomerPreOrderCheckout> & {
    isFetchPending: boolean
    hasMore: boolean
    meta: PaginationMeta
  }
  selectedPreOrderId: string | null
  selectedPreOrderListItem: CustomerPreOrderCheckout | null
  selectedPreOrderDetails: PreOrderCheckoutDetails | CheckoutDetails | null
  selectedPreOrderFetchPending: boolean

  /** Pre-order settings */
  preOrderSettings: PreOrderSettings | null
  preOrderSettingsFetchPending: boolean
}

export type AttendeePreOrderStartFormState = {
  name: string
  email: string
}

export type HostPreOrderStartFormState = {
  email: string
  pinCode: string
}

export enum PreOrderUserRole {
  Unknown = 'unknown',
  Host = 'host',
  Attendee = 'attendee',
}

export enum HostPreOrderStartAction {
  EnterPin,
  Activate,
  StartPreOrder,
}

export type PreOrderUser = {
  /** Attendee/Host ID */
  id: string
  email: string
  name?: string
  /** Pre-order host pin code */
  pin?: string
  isHost: boolean
}

export type PreOrderUserTokenPayload = PreOrderUser & {
  expiresAt: number
}

export type PreOrderCheckoutEntityType =
  | 'preOrderCheckout'
  | 'preOrderCheckoutSummary'

export type NewOrderDetails = {
  orderDate: string | null
  orderTime: number | null
  orderFormat: OrderFormat | null
  orderMethod: PreOrderMethod
  groupOrderName: string | null
  groupOrderSize: number | null
}

export enum NewOrderStep {
  None,
  OrderConfiguration,
  OrderMethodSelection,
  HostPreview,
  Summary,
  GroupOrderReceived,
  ShareGroupOrderLink,
}

export enum PreOrderMethod {
  Individual,
  Group,
}

export type NewOrderConfigurationFormState = {
  orderDate: string | null
  /** Timestamp of the order time */
  orderTime: number | null
  orderFormat: OrderFormat | null
}

export type GroupOrderFormState = {
  groupOrderName: string
  groupOrderSize: number
}

export type CustomerPreOrderListType =
  | 'upcomingOrderList'
  | 'calendarPreOrderList'
